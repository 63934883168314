import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckType } from '@school-dashboard/enums';
import { environment } from '@school-dashboard/environments';
import { ExamTrainingPurchaseCount, ExamTrainingTrack, Track } from '@school-dashboard/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { isAfter, parseISO } from 'date-fns';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CacheIdentifierService } from '../../shared/services/cache-identifier/cache-identifier.service';
import {
  GetExamTrainingTrackTrainingPurchaseCountResponse,
  GetTrackResponse,
  PurchaseCountInterface,
} from './track.response';
import { serializeTrack } from './track.serializer';

@Injectable({
  providedIn: 'root',
})
export class TrackApi {
  constructor(
    private httpClient: HttpClient,
    private cache: CacheService,
    private cacheIdentifierService: CacheIdentifierService,
  ) {}

  /**
   * Retrieve a list of tracks for the given location.
   */
  public getTracks(
    locationIri: string,
    onlyUpcomingTracks: boolean = false,
  ): Observable<Track[]> {
    let httpParams: HttpParams = new HttpParams();
    if (onlyUpcomingTracks) {
      httpParams = httpParams.set('inFuture', true);
    }
    const url: string = environment.dashboardApiUrl + locationIri + '/tracks';
    const cacheId: string = onlyUpcomingTracks ? this.cacheIdentifierService.getUpcomingTracksCacheIdentifier(
      locationIri,
    ) : this.cacheIdentifierService.getAllTracksCacheIdentifier(locationIri);

    return this.fetchAndStoreTracks(url, httpParams, cacheId);
  }

  public getTracksStore(
    locationIri: string,
    onlyUpcomingTracks: boolean = false,
  ): Observable<Resource<GetTrackResponse>[]> {
    let httpParams: HttpParams = new HttpParams();
    if (onlyUpcomingTracks) {
      httpParams = httpParams.set('inFuture', true);
    }
    const url: string = environment.dashboardApiUrl + locationIri + '/tracks';

    return this.httpClient.get<Collection<GetTrackResponse>>(url, { params: httpParams }).pipe(
      // TODO Create separation in active and non-active tracks.
      //  https://gitlab.com/techniek-team/back-office/school-dashboard/-/issues/356.
      map(track => track['hydra:member']),
      map(tracks => tracks.filter((track: Resource<GetTrackResponse>) => isAfter(
        parseISO(track.validityRange.end),
        new Date('2024-08-01'),
      ))),
    );
  }

  public getTrackStore(
    trackId: string,
  ): Observable<Resource<GetTrackResponse>> {
    const url: string = environment.dashboardApiUrl + '/api/tracks/' + trackId;

    return this.httpClient.get<Resource<GetTrackResponse>>(url);
  }

  /**
   * Retrieve practice tracks for the given location and use the cache to store
   * and retrieve the Practice Tracks.
   */
  public getPracticeTracks(locationIri: string): Observable<Track[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('location', locationIri);
    const url: string = environment.dashboardApiUrl + '/api/practice-tracks';
    const cacheId: string = this.cacheIdentifierService.getPractiseTracksCacheIdentifier(locationIri);

    return this.fetchAndStoreTracks(url, httpParams, cacheId);
  }

  /**
   * Retrieve the purchase count for the given exam track and join together all
   * remaining pages, before returning the calculated values for the combined
   * pages data.
   */
  public getPurchaseCountExamTrainingTrackTotalStore(track: string): Observable<PurchaseCountInterface> {
    const itemsPerPage: number = 40;

    const url: string = `${environment.dashboardApiUrl}/api/exam-training-tracks/${track}/exam-trainings`;
    let httpParams: HttpParams = new HttpParams()
      .set('groups[]', 'purchase_count')
      .set('itemsPerPage', itemsPerPage);

    return this.getPurchaseCountExamTrainingTrack(url, httpParams).pipe(
      switchMap((dataOfFirstPage: Hydra<ExamTrainingPurchaseCount>) => {
        let remainingPagesObservables: Observable<Hydra<ExamTrainingPurchaseCount>>[] = [];
        if (dataOfFirstPage.totalItems > itemsPerPage) {
          const numberOfPagesRemaining: number = Math.ceil(dataOfFirstPage.totalItems / itemsPerPage) - 1;
          remainingPagesObservables = this.createRemainingPagesObservables(numberOfPagesRemaining, url, httpParams);
        }

        if (remainingPagesObservables.length === 0) {
          return of(dataOfFirstPage);
        }

        return this.fetchOtherRemainingPages(remainingPagesObservables, dataOfFirstPage);
      }),
      map((combinedData: Hydra<ExamTrainingPurchaseCount>) => {
        const base: PurchaseCountInterface = { track: track, purchaseCount: 0, totalCount: 0 };

        return combinedData.collection
          .reduce((combined: PurchaseCountInterface, current: PurchaseCountInterface | ExamTrainingPurchaseCount) => {
            combined.purchaseCount += current.purchaseCount;
            combined.totalCount += current.totalCount;
            return combined;
          },
          base) as PurchaseCountInterface;
      }),
    );
  }

  public getPurchaseCountExamTrainingTrackTotal(track: ExamTrainingTrack): Observable<ExamTrainingPurchaseCount> {
    const itemsPerPage: number = 40;

    const url: string = environment.dashboardApiUrl + (track.getIri() as string) + '/exam-trainings';
    let httpParams: HttpParams = new HttpParams()
      .set('groups[]', 'purchase_count')
      .set('itemsPerPage', itemsPerPage);

    return this.getPurchaseCountExamTrainingTrack(url, httpParams).pipe(
      switchMap((dataOfFirstPage: Hydra<ExamTrainingPurchaseCount>) => {
        let remainingPagesObservables: Observable<Hydra<ExamTrainingPurchaseCount>>[] = [];
        if (dataOfFirstPage.totalItems > itemsPerPage) {
          const numberOfPagesRemaining: number = Math.ceil(dataOfFirstPage.totalItems / itemsPerPage) - 1;
          remainingPagesObservables = this.createRemainingPagesObservables(
            numberOfPagesRemaining,
            url,
            httpParams,
          );
        }

        if (remainingPagesObservables.length === 0) {
          return of(dataOfFirstPage);
        }

        return this.fetchOtherRemainingPages(
          remainingPagesObservables,
          dataOfFirstPage,
        );
      }),
      map((combinedData: Hydra<ExamTrainingPurchaseCount>) => {
        track.createPurchaseCountMap(combinedData);
        return this.calculateTotalPurchaseCount(combinedData);
      }),
    );
  }

  /**
   * Join together the remaining pages in an observable array.
   */
  private createRemainingPagesObservables(
    numberOfPagesToFetch: number,
    url: string,
    httpParams: HttpParams,
  ): Observable<Hydra<ExamTrainingPurchaseCount>>[] {
    const observables: Observable<Hydra<ExamTrainingPurchaseCount>>[] = [];

    const pageIndex: number = 2;
    const pagesToFetch: unknown[] = [...Array(numberOfPagesToFetch)];
    for (let [index] of pagesToFetch.entries()) {
      httpParams = httpParams.set('page', pageIndex + index);
      observables.push(this.getPurchaseCountExamTrainingTrack(url, httpParams));
    }

    return observables;
  }

  /**
   * Create http calls based on the array of observables and join it to the
   * first dataset.
   */
  private fetchOtherRemainingPages(
    nextPagesObservables: Observable<Hydra<ExamTrainingPurchaseCount>>[],
    dataOfFirstPage: Hydra<ExamTrainingPurchaseCount>,
  ): Observable<Hydra<ExamTrainingPurchaseCount>> {
    return combineLatest([...nextPagesObservables]).pipe(
      map((dataOfNextPages: Hydra<ExamTrainingPurchaseCount>[]) => {
        for (const dataOfNextPage of dataOfNextPages) {
          dataOfFirstPage.collection.push(...dataOfNextPage.collection);
        }
        return dataOfFirstPage;
      }),
    );
  }

  /**
   * Calculate the total purchase count for the entire collection of purchase
   * count items.
   */
  private calculateTotalPurchaseCount(
    purchaseCounts: Hydra<ExamTrainingPurchaseCount>,
  ): ExamTrainingPurchaseCount {
    const base: ExamTrainingPurchaseCount = denormalize(
      ExamTrainingPurchaseCount,
      {
        purchaseCount: 0,
        totalPurchaseCountOnMergedTraining: 0,
      },
    );

    return purchaseCounts.collection.reduce(
      (
        combined: ExamTrainingPurchaseCount,
        current: ExamTrainingPurchaseCount,
      ) => {
        combined.purchaseCount += current.purchaseCount;
        combined.totalCount += current.totalCount;
        return combined;
      },
      base,
    );
  }

  /**
   * Get the purchase count for the ExamTraining.
   */
  private getPurchaseCountExamTrainingTrack(
    url: string,
    httpParams: HttpParams,
  ): Observable<Hydra<ExamTrainingPurchaseCount>> {
    return this.httpClient
      .get<Collection<GetExamTrainingTrackTrainingPurchaseCountResponse>>(url, {
      params: httpParams,
    })
      .pipe(
        map(
          (
            response: Collection<GetExamTrainingTrackTrainingPurchaseCountResponse>,
          ) => {
            return denormalize(ExamTrainingPurchaseCount, response);
          },
        ),
      );
  }

  /**
   * Create a cache entry for the given url and cache it for a maximum of 30
   * seconds. If there's an error, clear the cache immediately.
   */
  private fetchAndStoreTracks(
    url: string,
    httpParams: HttpParams,
    cacheId: string,
  ): Observable<Track[]> {
    const call: Observable<Collection<GetTrackResponse>> = this.httpClient.get<
    Collection<GetTrackResponse>
    >(url, { params: httpParams });

    // Cache the results for 30 seconds, clear cache on any error, return the
    // cached hydra value toArray.
    return this.cache.create<Collection<GetTrackResponse>>(cacheId, call, { clearInterval: 30000 }).pipe(
      catchError((error) => {
        // If there's an error, clear any result immediately.
        this.cache.clear(cacheId);
        return throwError(error);
      }),
      map((response: Collection<GetTrackResponse>) => serializeTrack(response)),
      map((response) => response.toArray()),
      // TODO Create separation in active and non-active tracks.
      //  https://gitlab.com/techniek-team/back-office/school-dashboard/-/issues/356.
      map((tracks: Track[]) => {
        return tracks.filter((track: Track) => isAfter(track.validityRange.end, new Date('2024-08-01')));
      }),
    );
  }

  public approveCheck(
    track: string | Track,
    type: CheckType,
  ): Observable<void> {
    if (track instanceof Track) {
      track = track.getIri();
    }
    return this.httpClient.post<void>(
      `${environment.dashboardApiUrl}/api/checks`,
      {
        track: track,
        type: type,
      },
    );
  }
}
